@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2023';
@import 'styles/colors-2023';

.container {
	background-color: $neutral-black;
	color: $neutral-white;

	height: 200vh;
}

.cart {
	position: sticky;
	top: 0;
	height: 100vh;
}

.image_wrapper {
	height: 100%;
	position: absolute;
	inset: 0;
}

.image {
	display: block;
	height: 100%;

	img {
		width: 100%;
		height: 100%;

		object-position: center 90%;

		@include xl-up {
			object-position: center 70%;
		}
	}
}

.phone_wrapper {
	height: 100%;
	width: 100%;
	position: absolute;

	display: flex;
	justify-content: center;
	align-items: center;

	@include md-up {
		margin-left: 25vw;
	}
}

.frame_wrapper {
	position: absolute;
	z-index: 1;

	width: 345px;
}

.notif_wrapper {
	position: absolute;
	z-index: 3;

	border-radius: 10px;
	backdrop-filter: blur(10px);

	width: 320px;
	margin-bottom: 73px;
}

.time_wrapper {
	position: absolute;
	z-index: 2;
	margin-bottom: 220px;

	p {
		font-size: 56px;
		text-align: center;
		font-family: 'SF Pro Display', var(--neue-montreal), sans-serif;
		font-weight: 100;

		letter-spacing: -0.24px;
		line-height: 67px;
	}
}
