@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2023';
@import 'styles/colors-2023';

.container {
	isolation: isolate;
	background-color: $neutral-white;

	@include md-up {
		padding: 0 4.5rem 7.5rem;
	}
}

.inner_container {
	background-color: $neutral-5;
	color: $neutral-black;
	padding-top: 3.75rem;
	padding-bottom: 2rem;

	@include md-up {
		padding: 5.5rem;
	}
}

.slider {
	position: relative;
}

.slider_container {
	position: relative;

	button[aria-label='Previous slide'] {
		margin-left: 2rem;
	}

	button[aria-label='Next slide'] {
		margin-right: 2rem;
	}

	@include md-up {
		display: none;
	}
}

.header {
	padding: 0 1rem;
	display: flex;
	flex-direction: column-reverse;

	h2 {
		@include heading5;
		max-width: 16ch;
	}

	h3 {
		@include subheading4;
		color: $accent-5;
		margin-bottom: 0.675rem;
	}

	margin-bottom: 2rem;

	@include md-up {
		text-align: center;

		h2 {
			@include heading4;
			margin-inline: auto;
			max-width: unset;
		}

		h3 {
			margin-inline: auto;
			@include subheading2;
		}

		margin-bottom: 4rem;
	}

	.cta_button {
		margin-top: 20px;
	}
}

.card {
	text-align: center;
	position: relative;

	margin-inline: auto;

	height: 420px;

	perspective: 2000px;
	perspective-origin: 50% 50%;

	img {
		display: block;
		object-position: top;
		overflow: hidden;
		height: 100%;
	}

	h3 {
		@include subheading1;
		position: absolute;
		top: 1.25rem;
		left: 1.25rem;
		right: 1.25rem;
		text-align: left;
		z-index: 1;
		color: $neutral-white;
		text-shadow: rgba(0, 0, 0, 0.25) 0 0 10px;
		max-width: 12ch;
	}

	margin-bottom: 2rem;

	@include sm-down {
		margin-top: 1rem;
		margin: 1rem;
		max-width: none;
	}
}

.desktop_icons {
	list-style: none;
	padding: 0;
	display: none;
	grid-template-columns: 1fr 1fr 1fr;
	margin: 3.75rem 0;

	@include md-up {
		display: grid;
	}

	li {
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		gap: 10px;
	}

	span {
		display: inline-block;
		height: 3rem;
		width: 3rem;
	}

	p {
		@include subheading1;
	}
}

.desktop_image {
	display: none;
	@include md-up {
		display: block;
	}

	img {
		max-height: 460px;
	}
}

.desktop_list {
	list-style: none;
	display: none;

	width: 100%;
	padding: 0;
	margin: 0;

	@include md-up {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		max-width: 1170px;
		gap: 2rem;
		margin-inline: auto;
	}

	.card {
		margin-bottom: 0;
	}

	img {
		height: 420px;
		width: 100%;
		min-width: 230px;
	}
}

$flip-time: 0.4s;
.side {
	position: absolute;
	top: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	right: 0;
	left: 0;

	transform: scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) translate3d(0px, 0px, 0px) skew(0deg, 0deg);

	transition: all $flip-time ease-out;
	transition-delay: $flip-time;

	transform-style: preserve-3d;

	border-radius: 10px;
	overflow: hidden;

	.backsideText {
		@include subheading2;
		text-align: left;
		color: $neutral-white;
		margin: 2rem;
	}

	img {
		z-index: -2;
		object-position: center 25%;
	}
}

.shade {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.8) 100%);
	border-radius: 10px;
	z-index: -1;
}

.check {
	cursor: pointer;

	position: absolute;
	bottom: 1rem;
	right: 1rem;

	span {
		width: 4rem;
		height: 4rem;
		display: block;
	}

	// TODO this isn't accessible because we need to check for focus on the input, not the label
	&:hover {
		svg {
			fill: rgba(255, 255, 255, 0.25);
		}
	}
}

.front_side_flipped {
	transform: scale3d(1, 1, 1) rotateX(0deg) rotateY(90deg) rotateZ(0deg) translate3d(0px, 0px, 0px) skew(0deg, 0deg);
	transition-delay: 0s !important;
	transition-timing-function: ease-in;
	visibility: hidden;
}

.back_side {
	background-color: black;
}

.back_side_flipped {
	transform: scale3d(1, 1, 1) rotateX(0deg) rotateY(-90deg) rotateZ(0deg) translate3d(0px, 0px, 0px) skew(0deg, 0deg);
	transition-delay: 0s !important;
	transition-timing-function: ease-in;
	visibility: hidden;
}

.cta_container {
	margin-top: 2.5rem;

	@include md-up {
		margin-top: 3.75rem;
	}

	display: flex;
	justify-content: center;
	align-items: center;
}

.caption_container {
	position: absolute;
	bottom: 1.25rem;
	left: 1.25rem;
	text-align: left;
	color: $neutral-white;

	strong {
		@include subheading5;
	}

	p {
		@include body6;
	}

	@include sm-up {
		bottom: 2rem;
		left: 2rem;

		strong {
			@include subheading4;
		}

		p {
			@include body5;
		}
	}
}
