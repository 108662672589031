@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2023';
@import 'styles/colors-2023';

.container {
	background-color: $neutral-white;
	color: $neutral-black;

	padding: 3.75rem 1.5rem 2rem;

	h2 {
		@include heading5;
		margin-bottom: 0.75rem;
	}

	p {
		@include body5;
	}

	@include md-up {
		padding: 3.75rem 1.5rem;
		text-align: center;

		h2 {
			@include heading4;
			margin-bottom: 1rem;

			max-width: 23ch;
			margin-inline: auto;
		}

		p {
			@include body4;

			max-width: 58ch;
			margin-inline: auto;
		}
	}
}
