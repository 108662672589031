@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.container {
	color: $black;

	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
	gap: 0.5rem;

	&[data-has-badge='true'] {
		margin-top: 0.25rem;
	}
}

.stacked div[class*='standard_card'] {
	align-items: flex-start !important;
	padding: 20px !important;
	position: relative;

	div[class*='checklist_wrapper'] {
		border-top: 1px solid $text-grey-light;
		padding-top: 1rem;
		width: 100%;
	}

	p {
		text-align: left !important;
	}

	> span[class*='badge'] {
		position: absolute;
		top: 20px;
		right: 20px;
		left: auto;
		transform: none;
	}
}
