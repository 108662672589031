@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2023';
@import 'styles/colors-2023';

.container {
	background-color: $neutral-8;
	color: $neutral-black;
	padding: 3.75rem 0;
	padding-bottom: 0;

	overflow: hidden;

	@include md-up {
		padding: 7.5rem 0;
		padding-bottom: 0;
	}
}

.content {
	position: relative;
	display: grid;
	grid-template-areas:
		'header'
		'image'
		'animation';

	h2 {
		grid-area: header;
		@include heading5;
		margin: 0 1.5rem 2rem;
		max-width: 13ch;
	}

	@include sm-up {
		grid-template-areas:
			'header image'
			'animation image';
	}

	@include md-up {
		grid-template-areas:
			'header image'
			'animation image';

		h2 {
			@include heading4;
			max-width: 19ch;

			margin: 0 4rem;
		}
	}
}

.image {
	margin: 0 1.5rem;
	grid-area: image;
	position: relative;

	padding-left: 108px;

	@include sm-up {
		padding-left: 0;
		margin: 1.5rem 4rem 0 0;
	}

	@include md-up {
		height: 100%;

		max-height: 520px;
		img {
			height: 100%;
		}
	}
}

.big_image {
	display: block;
	height: 100%;
}

.stats_container {
	position: absolute;
	padding: 0.5rem 0.75rem;

	background: rgba(49, 49, 49, 0.7);
	backdrop-filter: blur(6px);
	border-radius: 11.25px;

	display: flex;

	bottom: -2rem;
	left: 0;

	@include sm-up {
		top: -2rem;
		bottom: auto;
		left: -2rem;

		padding: 0.75rem 1rem;
		border-radius: 15px;
	}
}

.divider {
	margin: 0 1rem;
	width: 1px;
	background-color: rgba(255, 255, 255, 0.15);
}

.stat {
	display: flex;
	align-items: center;
	gap: 0.5rem;
}

.stat_text {
	color: $neutral-white;

	p {
		@include body7;
		&:first-child {
			margin-bottom: 0.25rem;
			font-weight: bold;
		}
	}

	strong {
		font-size: 1rem;
	}

	@include sm-up {
		p {
			@include body5;
		}

		strong {
			font-size: 1.25rem;
		}
	}
}

.icon_wrapper {
	height: 1.25rem;
	width: 1.25rem;

	@include sm-up {
		height: 1.5rem;
		width: 1.5rem;
	}
}

.animation {
	padding: 6rem 0;
	@include sm-up {
		margin-bottom: 6rem;
	}
}

.cta_container {
	margin: 2rem auto;
	display: flex;
	justify-content: center;
	align-items: center;

	grid-column: 1 / -1;

	@include sm-up {
		margin: 4rem auto 2rem;
	}
}

.cta {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 0.625rem;
	cursor: pointer;

	svg {
		width: 3rem;
		height: 3rem;
	}

	p {
		@include subheading4;
		color: $neutral-black;
	}
}

.how_it_works_wrapper {
	position: relative;
	z-index: 11;
	background-color: $neutral-8;
	inset: 0;
	bottom: 4rem;

	transition: all 0.5s ease-in-out;
	padding: 0 1.5rem;

	display: flex;
	flex-direction: column;
	margin: 0;
	overflow: hidden;

	max-height: 0;
	grid-column: 1 / -1;
}

.how_it_works_inner {
	display: flex;
	align-items: center;
	flex-direction: column;
	overflow: hidden auto;

	background-color: $neutral-white;
	max-width: 65rem;
	margin: 0;
	margin-inline: auto;

	padding: 2rem;

	h3 {
		@include subheading2;
	}

	@include sm-up {
		padding: 5.5rem;

		h3 {
			@include heading5;
			text-align: center;
			max-width: 21ch;
			margin-inline: auto;
			margin-bottom: 3.75rem;
		}
	}
}

.how_it_works_opened {
	transform: translate3d(0, 0, 0);
	visibility: visible;

	max-height: 60rem;
	margin-bottom: 2rem;

	@include sm-up {
		max-height: 45rem;
		margin-bottom: 4rem;
	}
}

.how_it_works_list {
	list-style: none;

	@include sm-up {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: 1fr 1fr;
		row-gap: 2rem;
		column-gap: 4rem;
	}
}

.how_it_works_item {
	margin-top: 2rem;

	p {
		@include body6;
	}

	@include sm-up {
		margin-top: 0;
		p {
			@include body5;
		}
	}
}

.item_heading {
	display: flex;
	align-items: center;

	svg {
		width: 1.5rem;
		height: 1.5rem;

		margin-right: 0.5rem;
	}

	h4 {
		@include subheading4;
	}

	@include sm-up {
		h4 {
			@include subheading3;
		}

		margin-bottom: 1rem;
	}
}
