@import 'styles/mixins/pod-3-fonts';
@import 'styles/mixins/media-queries';
@import 'styles/constants';
@import 'styles/colors';

.container {
}

.panel {
	color: #000;
	background-color: transparent;
	border-bottom: 1px solid #adb3c5;

	&:first-of-type {
		border-top: 1px solid #adb3c5;
	}

	.content {
		padding: 0 0 15px 0;

		> p {
			margin: 0;
		}
	}

	> button {
		cursor: pointer;
		background-color: transparent;
		display: flex;
		width: 100%;
		border: none;
		min-height: 25px;
		font-weight: 700;
		font-size: 18px;
		padding: 20px 30px 20px 0;
		position: relative;
		text-align: left;
		color: #000;

		&:after,
		&:before {
			content: '';
			position: absolute;
			right: 5px;
			top: 0;
			width: 20px;
			height: 2px;
			margin-top: 30px;
			background-color: #000;
		}

		&:before {
			transform: rotate(-90deg);
			transition: transform 0.35s cubic-bezier(0.65, 0.05, 0.36, 1);
		}
	}

	&[aria-expanded='true'] > button:before {
		transform: rotate(0deg);
	}
}

.modal_p_outer {
	> div {
		display: inline-block;
	}
}
